<template>
  <div>

    <loading
        :active="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="true"
    />

    <!-- ALL ITEMS CARD -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" offset-md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search By Name, Type"
                v-model="keyword"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- ALl Item table -->
      <b-table
        id="NewProductListTableId"
        ref="refNewProductListTable"
        :items="allItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :per-page="0"
        :current-page="pagination.currentPage"
        :keyword="keyword"
        striped hover
        show-empty
      >
        <template #cell(index)="data">
          <span class="text-black">
            {{
              parseInt((pagination.currentPage - 1) * pagination.perPage) +
              data.index +
              1
            }}
          </span>
        </template>

        <template #cell(id)="data" class="text-black">
          <b-link
            :to="{
              name: `wp-add-product`,
              query: { id: data.item.id },
            }"
            class="font-weight-bold"
            @click="
              $router.push({
                name: `wp-add-product`,
                query: { id: data.item.id },
              })
            "
          >
            {{ data.item.id }}<br />
            <img :src="data.item.images[0].src" width="80%" alt="Image" v-if="data.item.images.length > 0" />
            <img :src='`https://www.revivify.com/wp-content/plugins/woocommerce/assets/images/placeholder.png`' width="80%" alt="Image" v-else />
          </b-link>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{
                data.item.name
              }}
            </span>
          </b-media>
        </template>

        <template #cell(sku)="data">
          <div>
            {{ data.item.sku ? data.item.sku : "__" }}
          </div>
        </template>

        <!-- <template #cell(images)="data">
          <div>
            <img :src="data.item.images[0].src" width="15%" alt="Image" v-if="data.item.images.length > 0" />
          </div>
        </template> -->

        <template #cell(stock_quantity)="data">
          <div>
            In Stock ({{ data.item.stock_quantity }})
          </div>
        </template>

        <template #cell(price)="data">
          <div>
            {{ data.item.price ? `₹&nbsp;${data.item.price}` : '-' }}
          </div>
        </template>

        <template v-slot:cell(categories)="data">
          <!-- Map and list links in the 'name' column -->
            <div>
            <span v-for="(cat, index) in data.item.categories" :key="index">
                <a :href="cat.name" style="white-space: nowrap;">{{ cat.name }}</a>, <br />
            </span>
            </div>
        </template>

        <template #cell(status)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.status }}
            </span>
          </b-media>
        </template>

      </b-table>

      <b-pagination
        class="pl-2"
        aria-controls="NewProductListTableId"
        v-model="pagination.currentPage"
        :total-rows="pagination.totalItems"
        :per-page="pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BTooltip,
  BCardText,
  BModal,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelectOption,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
//import { codeColumnSearch } from './code'

import Ripple from "vue-ripple-directive";
import feather from "feather-icons";
import vSelect from "vue-select";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import UserManagementServices from "@/apiServices/UserManagementServices";
import ProductServices from "@/apiServices/ProductServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MasterServices from "@/apiServices/MasterServices";
import moment from "moment";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BTooltip,
    BCardText,
    BModal,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelectOption,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    feather,
    FeatherIcon,
    moment,
    vSelect,
    Loading
  },

  beforeMount() {
    this.loadAllItems(0);
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 30,
      dir: false,
      //codeColumnSearch,
      rows: [],
      searchTerm: "",

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      allItemsData: [],
      keyword: "",
      pagination: {
        currentPage: 1,
        perPage: 30,
        totalItems: 0,
        perPageOptions: [30, 40, 50, 100, 200, 500, 1000],
      },
      PaginationCount: "30",
      tableColumns: [
        "index",
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Name", sortable: true },
        //{ key: "images", label: "Image", sortable: false },
        { key: "sku", label: "SKU", sortable: true },
        { key: "stock_quantity", label: "Stock", sortable: true },
        { key: "price", label: "Price", sortable: true },
        { key: "categories", label: "Category", sortable: true, width: '100px' }, //fields: this.itemCategories
        { key: "status", label: "Status", sortable: true }
      ],
      paginatorCurrentPage: 1,
      isLoading: true,
    };
  },

  computed: {
    allItems() {
        return this.keyword
          ? this.allItemsData.filter((item) => {
              if (item.name) {
                if (
                  item.name
                    .toString()
                    .toLowerCase()
                    .includes(this.keyword.toString().toLowerCase())
                ) {
                  console.log("Searching => ", item);
                  return true;
                } else {
                  console.log("Saerch False =>", item);
                  return false;
                }
              }
            })
          : this.allItemsData;
      },
  },

  watch: {
    'pagination.currentPage': {
      handler: function(pageNo) {
        this.loadAllItems(pageNo);
        /* this.fetchData().catch(error => {
          console.error(error)
        }) */
      }
    }
  },

  methods: {

    loadAllItems(pageNumber) {
        this.isLoading = true;
        ProductServices.getAdminWPProducts(pageNumber, "INR")
        .then((response) => {
          this.allItemsData = response.data.data.products;
          console.log("loadAllItems", response.data.data.products.length); //{pagination, products}
          this.pagination.totalItems = response.data.data.pagination.totalProducts;
          this.isLoading = false;
          /* if (parseInt(page) > 1) {
            this.pagination.currentPage = parseInt(page);
            this.paginatorCurrentPage = parseInt(page);

            "pagination": {
                "totalProducts": 514,
                "totalPages": 18,
                "currentPage": 1,
                "perPage": 30
            }

          } */
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error in getAdminWPProducts!", error);
        });
    },

    getHumanDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    Toast(message, icon, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: type,
        },
      });
    },

    onCancel() {
        console.log("Seller cancelled the loader.");
    },
  },
};
</script>

<style>
.table {
    color: #444 !important;
  }
  
  [dir] .table th,
  [dir] .table td {
    padding: 1.5rem;
  }
  
  [dir] .card {
    border-radius: 1rem !important;
  }
  
  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    background-color: #ffffff00 !important;
  }
  
  [dir] .table thead th {
    border-bottom: 0px !important;
  }
  
  th > div {
    color: black !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }

  table tr td:nth-child(2) {
  /* Add your styling here */
  text-align: center;
  width: 10%;
}

</style>
